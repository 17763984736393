import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetSupplierServiceRegionsApi } from './use-get-supplier-service-regions-api';

const endpoint = 'ums/v1/suppliers';
export const patchSupplierServiceRegionsEndpointIdentifier = endpoint;

interface BodyType {
  isActive?: boolean;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  serviceRegionId: number;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function usePatchSupplierServiceRegionApi(options?: Options): ReturnType {
  const { supplierId } = useParams<{ supplierId: string }>();
  const { refetch: refetchSupplierServiceRegions } = useGetSupplierServiceRegionsApi(parseInt(supplierId));
  const toast = useCustomToast();

  const mutationFn = async ({ serviceRegionId, body }: TVariables) => {
    try {
      const { data } = await http.patch(endpoint + `/${supplierId}/regions/${serviceRegionId}`, body);

      // Refetch users list
      refetchSupplierServiceRegions();

      toast({
        description: 'Service region have been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response?.status || !error?.response?.data?.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
