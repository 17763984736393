import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  SwitchProps,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { usePatchSupplierServiceRegionApi } from 'apis/supplier/use-patch-supplier-service-region-api';

export interface IServiceRegionActivationSwitchCell extends SwitchProps {
  serviceRegionId: number;
  isActive: boolean;
}

export const ServiceRegionActivationSwitch = (params: IServiceRegionActivationSwitchCell) => {
  const { serviceRegionId, isActive, ...rest } = params;
  console.log(params);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateServiceRegion, isLoading: isUpdateServiceRegionLoading } = usePatchSupplierServiceRegionApi();

  const handleActivateServiceRegion = () => {
    const variables = {
      serviceRegionId,
      body: {
        isActive: !isActive,
      },
    };

    updateServiceRegion(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <FormControl display='flex' alignItems='center'>
        <Switch id={`active-service-region-${serviceRegionId}`} onChange={onOpen} isChecked={isActive} {...rest} />
        <FormLabel
          htmlFor={`active-service-region-${serviceRegionId}`}
          mb='0'
          ms='sm'
          userSelect='none'
          fontWeight='normal'
          fontSize='sm'>
          {isActive ? 'Active' : 'Inactive'}
        </FormLabel>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isActive ? 'Deactivate' : 'Activate'} service region!</ModalHeader>

          <ModalBody>
            {isActive && (
              <>
                <Text>Are you sure you want to deactivate this service region?</Text>
              </>
            )}

            {!isActive && (
              <>
                <Text>Are you sure you want to activate this service region?</Text>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                onClick={handleActivateServiceRegion}
                isLoading={isUpdateServiceRegionLoading}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
