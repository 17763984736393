import {
  BoxProps,
  Button,
  ChakraSelect,
  formatOptionsArray,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUpdateSupplierServiceRegionApi } from 'apis/supplier/use-update-supplier-service-region-api';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: number;
  label: string;
};

type TInputs = {
  name: string;
  cutOffTime: string;
  deliverySla: string;
  weekdays: TOption[] | undefined;
};

const defaultValues: TInputs = {
  name: '',
  cutOffTime: '',
  deliverySla: '',
  weekdays: [],
};

export interface IEditServiceRegionModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  serviceRegion?: any;
}

export const EditServiceRegionModal = ({ serviceRegion, trigger }: IEditServiceRegionModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const weekdays = [
    { index: 0, name: 'Sunday' },
    { index: 1, name: 'Monday' },
    { index: 2, name: 'Tuesday' },
    { index: 3, name: 'Wednesday' },
    { index: 4, name: 'Thursday' },
    { index: 5, name: 'Friday' },
    { index: 6, name: 'Saturday' },
  ];

  const { isLoading: isUpdateServiceRegionLoading, mutate: updateServiceRegion } = useUpdateSupplierServiceRegionApi();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Update values
  useEffect(() => {
    if (!serviceRegion) return;

    const defaultFetchedValues: TInputs = {
      name: serviceRegion?.name,
      cutOffTime: serviceRegion?.cutOffTime,
      deliverySla: serviceRegion?.deliverySla,
      weekdays: formatOptionsArray(serviceRegion?.weekdays, 'index', 'name'),
    };

    reset(defaultFetchedValues);
  }, [isOpen, serviceRegion]);

  const onSubmit = (data: TInputs) => {
    const variables = {
      serviceRegionId: serviceRegion?.id,
      body: {
        name: data.name,
        cutOffTime: data.cutOffTime,
        deliverySla: data.deliverySla,
        weekdays: data.weekdays?.map((weekday) => weekday.value) as number[],
      },
    };

    updateServiceRegion(variables, {
      onSuccess: () => {
        onCloseModal();
      },
    });

    // Update form data
    reset(data);
  };

  const onCloseModal = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Service Region</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack as='form' id='addUserModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder='Please enter your name'
                  {...register('name', {
                    required: 'Name is required.',
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.cutOffTime}>
                <FormLabel>Cut Off Time</FormLabel>
                <Input
                  type='time'
                  placeholder='Please enter your cut off time'
                  {...register('cutOffTime', {
                    required: 'Cut off time is required.',
                  })}
                />
                <FormErrorMessage>{errors.cutOffTime?.message}</FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name='weekdays'
                rules={{ required: 'Weekdays are required.' }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error, invalid } }) => (
                  <FormControl isRequired isInvalid={invalid}>
                    <FormLabel>Weekdays</FormLabel>
                    <ChakraSelect
                      placeholder='Please select the weekdays'
                      options={formatOptionsArray(weekdays, 'index', 'name')}
                      id='weekdays'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                      isMulti
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addUserModal'
                isLoading={isUpdateServiceRegionLoading}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
