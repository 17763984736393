import { BasePagination, HStack, IconButtonWithTooltip, PenIcon, PlusIcon, VStack } from '@elkaso-app/web-design';
import { useGetSupplierByIdApi } from 'apis/supplier/use-get-supplier-by-id-api';
import { useGetSupplierServiceRegionsApi } from 'apis/supplier/use-get-supplier-service-regions-api';
import { BaseTable, BaseTableContainer, setHiddenColumns, useCustomTableCheckboxes } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AddServiceRegionModalCell } from '../components/add-service-regions-modal';
import { EditServiceRegionModal, IEditServiceRegionModalCell } from '../components/edit-service-region-modal';
import {
  IServiceRegionActivationSwitchCell,
  ServiceRegionActivationSwitch,
} from '../components/service-region-activation-switch';

export const SupplierServiceRegionsScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { supplierId } = useParams<{ supplierId: string }>();

  // Get supplier details
  const { data: supplierData } = useGetSupplierByIdApi(supplierId);

  // Get supplier regions
  const { isLoading, isFetching, data } = useGetSupplierServiceRegionsApi(parseInt(supplierId));
  const { pageCount, total, data: itemsData } = data ?? {};

  useCustomTableCheckboxes(itemsData);

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader name='Name' />,
        accessor: 'name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['name']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Cut Off Time' />,
        accessor: 'cutOffTime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['cutOffTime']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Delivery SLA' />,
        accessor: 'deliverySla',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['deliverySla']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Weekdays' />,
        accessor: 'weekdays',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['weekdays']>) => (
          <TableCell value={value.map((weekday) => weekday.name).join(', ')} />
        ),
      },
      {
        Header: <TableHeader name='Status' />,
        accessor: 'activation',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['activation']>) => (
          <CustomTableCell<IServiceRegionActivationSwitchCell>
            as={ServiceRegionActivationSwitch}
            serviceRegionId={value?.id}
            isActive={value?.isActive}
          />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IEditServiceRegionModalCell>
            as={EditServiceRegionModal}
            serviceRegion={value}
            trigger={
              <IconButtonWithTooltip
                size='sm'
                label='Edit'
                aria-label='edit-service-region-btn'
                Icon={<PenIcon />}
                variant='unstyled'
              />
            }
            variant={'add'}
          />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    name: string;
    cutOffTime: string;
    deliverySla: number;
    weekdays: { index: number; name: string }[];
    activation: { id: number; isActive: boolean };
    actions: (typeof itemsData)[0];
  }

  const columnsAccessorData = useMemo(
    () =>
      itemsData?.map((item: (typeof itemsData)[0]): IColumnsAccessorData => {
        return {
          id: item?.id,
          name: item?.name,
          cutOffTime: item?.cutOffTime,
          deliverySla: item?.deliverySla,
          weekdays: item?.weekdays || [],
          activation: {
            id: item?.id,
            isActive: item?.isActive,
          },
          actions: item,
        };
      }),
    [itemsData]
  );

  return (
    <VStack spacing='sm' align='stretch'>
      <BaseTableContainer isLoading={isLoading}>
        <BaseTableContainer.Header>
          <BaseTableContainer.Header.Left>
            <VStack spacing='sm' align='start'>
              <HStack>
                <BaseTableTitle title={`Suppliers > ${supplierData?.data?.name} > Service Regions`} />
                <BaseTableSpinner isFetching={isFetching} />
              </HStack>
            </VStack>
          </BaseTableContainer.Header.Left>

          <BaseTableContainer.Header.Right>
            <ClearFilterButton />

            <AddServiceRegionModalCell
              trigger={
                <IconButtonWithTooltip label='Create Service Region' aria-label='add-item-btn' Icon={<PlusIcon />} />
              }
            />
          </BaseTableContainer.Header.Right>
        </BaseTableContainer.Header>

        <BaseTableContainer.TableWrapper>
          <BaseTable
            columns={columns}
            data={columnsAccessorData}
            initialHiddenColumns={setHiddenColumns(['actions'])}
          />
        </BaseTableContainer.TableWrapper>

        <BaseTableContainer.Footer>
          <BaseTableContainer.Footer.Left />
          <BaseTableContainer.Footer.Right>
            <BasePagination>
              <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
              <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
            </BasePagination>
          </BaseTableContainer.Footer.Right>
        </BaseTableContainer.Footer>
      </BaseTableContainer>
    </VStack>
  );
};
